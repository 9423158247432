<template>
  <div class="card">
    <div class="card-header border-0 pt-6 mb-5">
        <div class="card-title">
        <div class="d-flex align-items-center position-relative">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            :placeholder="productSkuConstants.SEARCHSKUCODE"
          />
        </div>
        <div class="d-flex align-items-center position-relative my-1 w-250px me-2">
          <el-select
            size="large"
            v-model="productFormData.productId"
            filterable
            :placeholder="generalConstants.SELECTPRODUCT"
          >
            <el-option :label="pdata.product_name" :value="pdata.product_id" :key="pdata"  v-for="pdata in productData"></el-option>
          </el-select>
        </div>
        <div class="d-flex align-items-center position-relative ms-2">
          <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
              <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
            <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
          </el-tooltip>
        </div>
      </div>
        <div class="card-toolbar" v-if="tableData.length > 0 && permission.isExportAllowed">
          <button type="button" class="btn btn-gray" data-bs-toggle="modal" data-bs-target="#sku_fields_modal" @click="getTradingPartners()"><i class="bi bi-file-earmark-arrow-down fs-4"></i>
          {{ productSkuConstants.EXPORTSKU }}
        </button>
        </div>
    </div>
    <div class="card-body pt-0">

    <div class="modal fade" tabindex="-1" id="trading_partner_modal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">Partner SKU Data ({{platformModalSKUCode}})</h2>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <table class="table table-row-dashed fs-6 datatable gy-2 gs-2">
                <thead>
                  <tr class="text-start text-gray-800 fw-bolder fs-7 text-uppercase gs-0 text-center">
                    <th>{{ 'Sr#' }}</th>
                    <th>{{ 'Platform Name' }}</th>
                    <th>{{ 'Partner SKU Code' }}</th>
                    <th>{{ 'Partner SKU Description' }}</th>
                  </tr>
                </thead>
                <tbody class="fw-bold text-gray-600">
                  <tr v-for="(skudata, index) in partnerSkuData" :key="index" v-bind:id="'row_'+index" align="center">
                    <td width="80">{{ index + 1 }}</td>
                    <td width="350">
                      {{ skudata.partner.get_platform.platform_name }}
                    </td>
                    <td width="400">
                      {{ skudata.partner_sku }}
                    </td>
                    <td width="600">
                      <template v-if="skudata.partner_sku_description != NULL && skudata.partner_sku_description.length > 100">
                        {{ skudata.partner_sku_description != NULL ? skudata.partner_sku_description.substring(0,100)+'...' : '-' }}
                      <el-tooltip v-if="skudata.partner_sku_description.length > 100" class="box-item" effect="dark" :content="skudata.partner_sku_description.match(/.{1,50}/g).join('<br/>')" raw-content placement="top"><i class="bi bi-question-circle-fill"></i></el-tooltip>
                      </template>
                      <template v-else>
                        {{ skudata.partner_sku_description != NULL ? skudata.partner_sku_description : '-' }}
                      </template>
                    </td>
                    <td>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" id="sku_fields_modal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select Fields Which You Want To Export With SKU (Optional)</h5>
            <div class="btn btn-icon btn-sm btn-active-color-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <el-checkbox-group v-model="checkedSKUExportFields">
                  <el-checkbox v-for="(fields, index) in SkuExportFields" :key="index" :label="index" :disabled="index == 'sku'">
                    {{ fields }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="row mt-5" v-if="checkedSKUExportFields.includes('partner_sku') == true">
              <div class="col-md-12">{{checkedTradingPartnerExportFields}}
                <label class="mb-2">Select Trading Partners To Export SKU</label>
                <el-checkbox-group v-model="checkedTradingPartnerExportFields">
                  <el-checkbox v-for="(fields, index) in tradingPartnerData" :key="index" :label="fields.trading_partner_id">
                    {{ fields.store_name+' ('+fields.platform_name+')' }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-gray" @click="exportSku('xlsx')"><i class="bi bi-file-earmark-arrow-down fs-4"></i>
              {{ productSkuConstants.SKU_EXCEL_EXPORT }}
            </button>
            <button type="button" class="btn btn-gray" @click="exportSku('csv')"><i class="bi bi-file-earmark-arrow-down fs-4"></i>
              {{ productSkuConstants.SKU_CSV_EXPORT }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" id="sku_weight_modal">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">Update Sku/Weight Data</h2>
            <div class="btn btn-icon btn-sm btn-active-color-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
          </div>
          <div class="modal-body">
            <div class="row g-7">
              <div class="col-md-12 fv-row">
                <label>{{ productSkuConstants.MODIFYSKU }}</label>
                <el-input size="large" v-model="skuWeightData.modifySku" :placeholder="productSkuConstants.MODIFYSKU" class="me-2 text-capitalize" />
              </div>
              <div class="col-md-12 fv-row">
                <label>{{ productSkuConstants.MODIFYWEIGHT }}</label>
                <el-input size="large" v-model="skuWeightData.weight" :placeholder="productSkuConstants.MODIFYWEIGHT" class="me-2" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' />
              </div>
              <div class="col-md-12 fv-row">
                <label>{{ 'SKU Description' }}</label>
                <el-input size="large"
                v-model="skuWeightData.skuDescription"
                :rows="4"
                type="textarea"
                placeholder="SKU Description"
              /> 
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary me-2" @click="updateSkuWeightData">{{generalConstants.UPDATE}}</button>
            <button type="button" class=" btn btn-secondary" id="skuWeightClose" data-bs-dismiss="modal">{{generalConstants.CANCEL}}</button>
          </div>
        </div>
      </div>
    </div>

      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
        @sort="handleSortChange"
      >
        <template v-slot:cell-id="{ row: data }">
          {{ data }}
        </template>
        <template v-slot:cell-name="{ row: data }">
          <template v-if="data.product_name != ''">
            <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="$router.push({ name: 'view-product', params: { id: data.product_id } })">{{ data.product_name }}</a>
          </template>
          <template v-else>-</template>
        </template>
        <template v-slot:cell-size="{ row: data }">
          {{ data.size }}
        </template>
        <template v-slot:cell-option_name="{ row: data }">
          <div class="fs-7 text-muted" v-if="data.option_name != ''">
            <span v-for="(optionVal, optionKey) in data.option_name" :key="optionKey">
              <span class="fs-7 text-muted">{{ optionVal+' ' }}<span class="attribute-name">{{ data.attribute_name[optionKey] }}</span><span v-if="optionKey != Object.keys(data.option_name).length - 1">, </span></span>
            </span>
          </div>
          <div v-else>
            {{ '-' }}
          </div>
        </template>
        <template v-slot:cell-sku="{ row: data }">
          <span class="sku-font me-1 attribute-name cursor-pointer" v-if="data.partner_sku_count != 0" @click.prevent="showPartnerSKU(data.product_sku_id,data.sku)" data-bs-toggle="modal" data-bs-target="#trading_partner_modal">{{ data.modify_sku == '' ? data.sku : data.modify_sku }}</span>
          <span class="sku-font me-1" v-else>{{ data.modify_sku == '' ? data.sku : data.modify_sku }}</span>
          <span v-if="data.partner_sku_count != 0" @click.prevent="showPartnerSKU(data.product_sku_id,data.sku)" data-bs-toggle="modal" data-bs-target="#trading_partner_modal" class="cursor-pointer badge rounded-pill bg-primary">{{ data.partner_sku_count }}</span>
        </template>
        <template v-slot:cell-sku_description="{ row: data }">
          <template v-if="data.sku_description != NULL && data.sku_description.length > 100">
            {{ data.sku_description != NULL ? data.sku_description.substring(0,100)+'...' : '-' }}
          <el-tooltip v-if="data.sku_description.length > 100" class="box-item" effect="dark" :content="data.sku_description.match(/.{1,50}/g).join('<br/>')" raw-content placement="top"><i class="bi bi-eye"></i></el-tooltip>
          </template>
          <template v-else>
            {{ data.sku_description != NULL ? data.sku_description : '-' }}
          </template>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isEditAllowed || permission.isShippingPackageAllowed">
            <el-tooltip class="box-item" v-if="permission.isEditAllowed" effect="dark" content="Update Sku/Weight Data" placement="top">
              <button type="button" @click.prevent="setSkuWeightData(data.product_sku_id, data.sku, data.modify_sku, data.sku_description, data.weight, tableData.findIndex((i) => i.product_sku_id === data.product_sku_id))" class="btn btn-icon btn-light btn-sm me-2" data-bs-toggle="modal" data-bs-target="#sku_weight_modal">
                <i class="bi bi-pencil-square"></i>
              </button>
            </el-tooltip>
            <el-tooltip class="box-item" v-if="permission.isShippingPackageAllowed" effect="dark" content="Manage Shipping Packages" placement="top">
              <button type="button" @click.prevent="manageShipping(data.product_sku_id, data.sku, data.modify_sku, data.product_id)" data-bs-toggle="modal" data-bs-target="#package_modal" class="btn btn-icon btn-light btn-sm">
                <i class="bi bi-box2"></i>
              </button>
            </el-tooltip>
          </div>
          <div v-else>
              -
          </div>
        </template>
      </Datatable>
    </div>
    <div v-if="permission.isShippingPackageAllowed">
      <AddEditShippingPackageModal
        :form-data="formData"
        @setPackageDetails="getProductShippingPackages"
      ></AddEditShippingPackageModal>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { notificationFire } from "@/composable/notification.js";
import AddEditShippingPackageModal from "@/components/modals/forms/AddEditShippingPackageModal.vue";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { useRoute } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";

export default {
  name: "sku-listing",
  components: {
    AddEditShippingPackageModal,
    Datatable
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const search = ref("");
    const pageSize = ref(10);
    const total = ref(0);
    const tableData = ref([]);
    const currentpage = ref(1);
    const indexNumber = ref();
    const updateProps = ref(null);
    const partnerSkuData = ref([]);
    const generalConstants = globalConstant.general;
    const productSkuConstants = globalConstant.productSku;
    const noDataFound = ref(generalConstants.LOADING);
    const platformModalSKUCode = ref('');
    const checkedSKUExportFields = ref(['sku']);
    const checkedTradingPartnerExportFields = ref([]);
    const tradingPartnerData = ref([]);
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const productFormData = ref({
      productId: "",
    });

    const permission = reactive({
      isEditAllowed: false,
      isExportAllowed: false,
      isShippingPackageAllowed: false
    })

    const searchParams = reactive({
      search: "",
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
      sortBy: 'product_sku_id',
      sortDesc: "desc",
      productId: productFormData.value.productId
    });

    const productSearchParams = reactive({
      sortBy: "sort_order",
      sortDesc: "asc"
    });

    const showPartnerSKU = (val,SkuCode) => {
      ApiService.post("partner-sku/fetch-partner-sku", {
        product_sku_id: val,
      }).then(({ data }) => {
        if(data.data){
          platformModalSKUCode.value = SkuCode;
          partnerSkuData.value.splice(0, partnerSkuData.value.length, ... data.data);
        }
        loading.value = false;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let status = response.status;
          let message = response.data.message;
          notificationFire(message, "error");
        }
        loading.value = false;
      });
    }

    const setSkuValue = (skuId, skuDescription, index) => {
      skuWeightData.value.skuId = skuId;
      skuWeightData.value.skuDescription = skuDescription;
      indexNumber.value = index;
    }

    const getTradingPartners = async () => {
      if(tradingPartnerData.value.length == 0){
      await ApiService.query("get-active-trading-partners")
        .then(({ data }) => {
          tradingPartnerData.value = [];
          data.data.forEach((obj) => {
            tradingPartnerData.value.push({
              trading_partner_id: obj.trading_partner_id,
              store_name: obj.get_user_oauth[0].name,
              platform_name: obj.get_platform.platform_name
            });
            checkedTradingPartnerExportFields.value.push(obj.trading_partner_id);
          });
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
      }
    };

    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: "Product Name",
        key: "name",
        sortable: false,
        width: "w-250px",
      },
      {
        name: "Size",
        key: "size",
        sortable: false,
        width: "w-100px",
      },
      {
        name: "Addition Options",
        key: "option_name",
        width: "w-250px",
        sortable: false,
      },
      {
        name: "SKU",
        key: "sku",
        width: "w-200px",
        sortable: true,
      },
      {
        name: "SKU Description",
        key: "sku_description",
        width: "w-400px",
        sortable: false,
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        width: "w-200px",
        sortable: false,
      },
    ]);

    const skuWeightData = ref({
      skuId: "",
      skuDescription: "",
      skuCode: "",
      modifySku: "",
      weight: "",
    });
    const productData = reactive([]);

    const getProductSkuData = async () => {
      searchParams.productId = productFormData.value.productId
      searchParams.pageNub = 1;
      getSkuData();
    }

    const pTableData = ref([]);
    //Form data for List & Submit
    const formData = reactive({
      product_sku_id: 0,
      product_sku: '',
      shipping_package_id: '',
      internal_package_id: '',
      external_package_id: '',
      shipping_package_status: true
    });

    //Get all product shipping packages
    const getProductShippingPackages = async () => {
      await ApiService.query("shipping-packages")
        .then(({ data }) => {
          if (data.data) {
            data.data.forEach((key) => {
              key.sku = (key.modify_sku != '') ? key.modify_sku : key.sku;
              if(key.shipping_package != null) {
                key.shipping_package.status = key.shipping_package.status == 1 ? true : false;
              }
            });
            pTableData.value = data.data;
          }
        })
        .catch(({ err }) => {
          pTableData.value = [];
        });
    };

    //Get all product shipping packages
    const manageShipping = async (skuId, sku, modifySku, productId) => {
      let isPackage = false;
      formData.product_id = productId;
      formData.product_sku_id = skuId;
      formData.product_sku = (modifySku != '') ? modifySku : sku;
      pTableData.value.forEach((obj) => {
        if (obj.product_sku_id == skuId) {
          isPackage = true;
          formData.shipping_package_id = (obj.shipping_package != null) ? obj.shipping_package.shipping_package_id : '';
          formData.internal_package_id = (obj.shipping_package != null && obj.shipping_package.internal_package != null) ? obj.shipping_package.internal_package_id : '';
          formData.external_package_id = (obj.shipping_package != null && obj.shipping_package.external_package != null) ? obj.shipping_package.external_package_id : '';
          formData.shipping_package_status = (obj.shipping_package != null) ? obj.shipping_package.status : true;
          return false;
        }
        if(!isPackage) {
          formData.shipping_package_id = formData.internal_package_id = formData.external_package_id = '';
          formData.shipping_package_status = true;
        }
      });
    };

    //reset search data
    const resetSearch = () => {
      searchParams.search = '';
      searchParams.productId = '';
      productFormData.value.productId = '';
      getSkuData();
    }

    //set particular sku details in object
    const setSkuWeightData = (skuId,skuCode,modifySku,skuDescription,weight,index) => {
      skuWeightData.value.skuId = skuId;
      skuWeightData.value.skuCode = skuCode;
      skuWeightData.value.modifySku = modifySku;
      skuWeightData.value.skuDescription = skuDescription;
      skuWeightData.value.weight = weight;
      indexNumber.value = index;
    }

    //update sku & weight data for particular sku
    const updateSkuWeightData = () => {
      ApiService.post("productsku/update-sku-weight", {
        data: skuWeightData.value,
      }).then(({ data }) => {
          tableData.value[indexNumber.value].weight = skuWeightData.value.weight;
          tableData.value[indexNumber.value].modify_sku = skuWeightData.value.modifySku;
          tableData.value[indexNumber.value].sku_description = skuWeightData.value.skuDescription;
          notificationFire(data.message, "success");
          loading.value = false;
          document.getElementById("skuWeightClose").click();
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let status = response.status;
          let message = response.data.message;
          notificationFire(message, "error");
        }
        loading.value = false;
        document.getElementById("skuWeightClose").click();
      });
    }

    //Fetch the all the products
    const getProducts = () => {
      ApiService.query('products', { params: productSearchParams })
        .then(({ data }) => {
          if (data) {
            productData.push({
                product_id: '',
                product_name: 'Select All Products',
              });
            data.data.forEach((obj) => {
              productData.push({
                product_id: obj.product_id,
                product_name: obj.name,
              });
            });
          }
        })
        .catch((error) => {
          let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              notificationFire(message, "error");
              if (status == 404 || status == 422) {
                router.push({ name: 'product-SKUs'})
              }
            }
        });
    }

    //Get all Sku Details
    const getSkuData = async () => {
      loading.value = true;
      await ApiService.post("productsku/fetch-sku-list", { params: searchParams })
        .then(({ data }) => {
          tableData.value = [];
          if (data.data.data.length) {
            data.data.data.forEach((obj) => {
              tableData.value.push({
                product_id: obj.product_id,
                product_sku_id: obj.product_sku_id,
                sku: obj.sku,
                modify_sku: obj.modify_sku,
                weight:obj.weight != null ? obj.weight.weight : 0,
                size: obj.get_size != null ? obj.get_size.size : '-',
                product_name: obj.get_product != null ? obj.get_product.name : '',
                sku_description: obj.sku_description,
                option_name: obj.option_name != null ? Object.values(obj.option_name).join(',').split(',') : '',
                attribute_name: obj.attribute_name != null ? Object.values(obj.attribute_name).join(',').split(',') : '',
                partner_sku_count: obj.partnersku_count,
              });
            });
            total.value = data.data.total;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
          } else {
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
          }
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          loading.value = false;
        })
        .catch((error) => {
          let response = error.response;
          if(response != ''){
            notificationFire(response.data.message, "error");
          }
          loading.value = false;
        });
    }

    //pagination page size change event function
    const handleSizeChange = (number) => {
      store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
      searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
      handleCurrentChange(1);
    };

    //pagination page change event function
    const handleCurrentChange = (number) => {
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      getSkuData();
    };

    //Product search event function
    const handleSearch = () => {
      searchParams.productId = productFormData.value.productId;
      handleCurrentChange(1);
    }

    //Handle sort change event
    const handleSortChange = async(obj) => {
      searchParams.sortBy = (obj.columnName == 'index') ? 'product_sku_id' : obj.columnName;
      searchParams.sortDesc = obj.order;
      await getSkuData();
    }

    //Export all sku in xlsx file
    const exportSku = async (extension) => {
      if(extension != "" && (extension == 'csv' || extension == 'xlsx')){
        axios({
          url: 'productsku/export-sku',
          method: 'POST',
          data: {
            extension: extension,
            search: searchParams.search,
            productId: productFormData.value.productId,
            exportFields: checkedSKUExportFields.value,
            checkedTradingPartnerExportFields: checkedTradingPartnerExportFields.value,
            tradingPartnerData: tradingPartnerData.value,
            sortBy: 'product_sku_id',
            sortDesc: "desc",
          },
          responseType: 'arraybuffer',
        }).then((response) => {
          let blob = new Blob([response.data], {
              type: ''
          })
          let link = document.createElement('a')
          link.setAttribute('target','_blank')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'SKU_'+Date.now()+'.'+ extension
          link.click()
        })
        .catch((error) => {
          let response = error;
            if (response != "") {
              notificationFire('sku data is not exported', "error");
            }
        });
      }else{
        notificationFire('Something went wrong.', "error");
      }
      
    }

    const SkuExportFields = reactive({
      sku: 'SKU Code',
      partner_sku: 'Partner SKU Code',
      name: 'Product Name',
      description: 'Product Description',
      size: 'Size Label',
      size_sku_code: 'Size SKU Code',
      additional_option: 'Additional Option'
    });

    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("exportProductSKU") || role == "Super Admin")
        permission.isExportAllowed = true;
      if (per.includes("updateProductSKU") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("updateShippingPackage") || role == "Super Admin")
        permission.isShippingPackageAllowed = true;
      await getSkuData();
      await getProducts();
      await getProductShippingPackages();
    })

    return {
      productFormData,
      resetSearch,
      loading,
      tableData,
      search,
      getSkuData,
      total,
      currentpage,
      pageSize,
      handleSizeChange,
      handleCurrentChange,
      handleSearch,
      productData,
      skuWeightData,
      setSkuWeightData,
      updateSkuWeightData,
      manageShipping,
      formData,
      getProductShippingPackages,
      pTableData,
      indexNumber,
      generalConstants,
      productSkuConstants,
      tableHeader,
      searchParams,
      noDataFound,
      updateProps,
      exportSku,
      getProductSkuData,
      permission,
      showPartnerSKU,
      partnerSkuData,
      setSkuValue,
      platformModalSKUCode,
      SkuExportFields,
      checkedSKUExportFields,
      handleSortChange,
      tradingPartnerData,
      checkedTradingPartnerExportFields,
      getTradingPartners
    };
  },
};
</script>
<style lang="scss">
@import 'https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap" rel="stylesheet';
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
 // For Export Button
.btn.btn-gray{
  background-color: #858585;
  border-color: #858585;
  color:#ffffff;
}
.btn.btn-gray i{color:#ffffff;}
.btn.btn-gray svg path{fill:#ffffff !important;}
.btn.btn-gray:hover,.btn.btn-gray:focus,.btn.btn-gray:active{
  background-color: #666666;
  border-color: #666666;
}
.menu-state-bg-light-gray .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .menu-state-bg-light-gray .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here){
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #f3f3f3;
  color: #858585;
}
.attribute-name {
  color:#009EF7;
}
.sku-font {
  font-family: 'Roboto Mono', monospace;
}
</style>